import React, { useCallback, useEffect, useState } from "react";
import { Card, CloseButton, Col, Form, Row } from "react-bootstrap";
// import { Typeahead } from "react-bootstrap-typeahead";
import Typeahead from "components/Typeahead";
import { v4 as genId } from "uuid";
import LayerFilterQueryOperatorSelector from "./LayerFilterQueryOperatorSelector";
import { LayerFilterQueryValueInput } from "./LayerFilterQueryValueInput";
import {
  LayerFilterQueryConditional,
  LayerFilterQueryOperator,
  LayerFilterSupportedLayers,
} from "./types";
import { ILayerFilterQuery } from "./types/ILayerFilterQuery";
import * as reactiveUtils from "@arcgis/core/core/reactiveUtils";

const FIELD_BLACKLIST = new Set(["OBJECTID"]);
const CONDITIONALS = Object.values(LayerFilterQueryConditional);

interface ILayerFilterQueryParams {
  additionalQuery?: boolean;
  className?: string;
  ignoreFields?: string[];
  layer?: LayerFilterSupportedLayers;
  query?: ILayerFilterQuery;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (query: ILayerFilterQuery) => any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRemove?: () => any;
}

export default function LayerFilterQuery({
  additionalQuery,
  className,
  layer,
  query,
  onChange,
  onRemove,
  ignoreFields,
}: ILayerFilterQueryParams) {
  const [uid, setUid] = useState(genId());
  const [fields, setFields] = useState<__esri.Field[]>([]);
  const [conditional, setConditional] = useState<
    LayerFilterQueryConditional | undefined
  >();
  const [field, setField] = useState<__esri.Field>();
  const [operator, setOperator] = useState<
    LayerFilterQueryOperator | undefined
  >();
  const [value, setValue] = useState<string | number | undefined>();

  const [showValue, setShowValue] = useState(true);

  const handleLayerLoad = useCallback(() => {
    if (layer?.fields) {
      const fieldLookup = layer.fields.reduce((dict, field) => {
        if (ignoreFields?.includes(field.name)) {
          return dict;
        }
        dict[field.name] = field;
        return dict;
      }, {} as Record<string, __esri.Field>);
      const flds = Object.values(fieldLookup).filter(
        (field) => !FIELD_BLACKLIST.has(field.name)
      );
      setFields(flds);
    }
  }, [ignoreFields, layer?.fields]);

  useEffect(() => {
    if (layer) {
      if (layer.loadStatus === "not-loaded") {
        layer.load();
      }
      reactiveUtils
        .whenOnce(() => {
          return layer.fields?.length > 0;
        })
        .then(handleLayerLoad);
    }
  }, [handleLayerLoad, layer]);

  useEffect(() => {
    if (query?.uid) setUid(query.uid);
    if (query?.conditional) setConditional(query.conditional);
    if (query?.field) {
      // setField(query.field);
      if (fields) {
        const fld = Object.values(fields).find((f) => f.name === query.field);
        setField(fld);
      }
    }
    if (query?.operator) setOperator(query.operator);
    if (query?.value) setValue(query.value);
  }, [fields, query]);

  useEffect(() => {
    const show =
      operator !== LayerFilterQueryOperator.isBlank &&
      operator !== LayerFilterQueryOperator.isNotBlank;

    setShowValue(show);
    if (!show) setValue(undefined);
  }, [operator]);

  const emitQuery = ({
    conditional,
    field,
    operator,
    value,
  }: {
    conditional?: LayerFilterQueryConditional;
    field?: string;
    operator?: LayerFilterQueryOperator;
    value?: string | number;
  }) => {
    if (!onChange) return;

    onChange({
      uid,
      conditional,
      field,
      operator,
      value,
    });
  };

  return (
    <Card className={className}>
      <Card.Body>
        {additionalQuery && (
          <Row className="mb-3">
            <Col>
              {CONDITIONALS.map((cond) => (
                <Form.Check
                  key={cond}
                  label={cond}
                  value={cond}
                  checked={conditional === cond}
                  inline
                  name={`conditionals-${uid}`}
                  type="radio"
                  id={`conditional-${uid}-${cond}`}
                  onChange={(e) => {
                    const conditional = e.target
                      .value as LayerFilterQueryConditional;
                    setConditional(conditional);
                    emitQuery({
                      conditional,
                      field: field?.name,
                      operator,
                      value,
                    });
                  }}
                />
              ))}
            </Col>

            <Col sm="auto">
              {onRemove && (
                <CloseButton
                  variant="white"
                  className="align-text-top"
                  onClick={() => {
                    onRemove();
                  }}
                />
              )}
            </Col>
          </Row>
        )}

        <Typeahead
          id={`layer-filter-field-select-${uid}`}
          className="mb-3"
          clearButton
          placeholder="Choose a field"
          labelKey={"alias"}
          options={fields}
          onChange={([option]) => {
            const opt = option as __esri.Field;
            const f = opt?.name;
            const operator = undefined;
            const value = undefined;

            setField(opt);
            setOperator(operator);
            setValue(value);

            emitQuery({
              conditional,
              field: f,
              operator,
              value,
            });
          }}
          selected={field ? [field] : []}
        />

        <LayerFilterQueryOperatorSelector
          className={showValue ? "mb-3" : ""}
          field={field}
          value={operator}
          onChange={(operator) => {
            setOperator(operator);
            emitQuery({
              conditional,
              field: field?.name,
              operator,
              value,
            });
          }}
        />

        {showValue && (
          <LayerFilterQueryValueInput
            layer={layer}
            field={field}
            operator={operator}
            value={value}
            onChange={(value) => {
              setValue(value);
              emitQuery({
                conditional,
                field: field?.name,
                operator,
                value,
              });
            }}
          />
        )}
      </Card.Body>
    </Card>
  );
}
