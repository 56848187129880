import React from "react";
import "./linear-progress.scss";

export default function LinearProgress({
  className,
  isLoading,
  absolute,
}: {
  className?: string;
  isLoading?: boolean;
  absolute?: boolean;
}) {
  return (
    <div
      className={`slider${className ? ` ${className}` : ""}`}
      style={
        isLoading === true || isLoading === false
          ? {
              top: 0,
              left: 0,
              right: 0,
              position: "absolute",
              zIndex: 1,
              opacity: isLoading ? 1 : 0,
              transition: "opacity 1s linear",
            }
          : absolute
          ? { top: 0, left: 0, right: 0, position: "absolute", zIndex: 1 }
          : {}
      }
    >
      <div className="line" />
      <div className="subline inc" />
      <div className="subline dec" />
    </div>
  );
}
