function getIsApple() {
  return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

export default function getLinkToMapsAtLatLng({
  latitude,
  longitude,
}: {
  latitude: number | string;
  longitude: number | string;
}) {
  if (getIsApple()) {
    return `http://maps.apple.com/?q=${latitude},${longitude}&ll=${latitude},${longitude}`;
  }
  return `https://maps.google.com/maps/search/?api=1&query=${latitude},${longitude}`;
}
