import React, { useEffect, useRef, useState } from "react";
import EsriIconButton from "components/EsriMap/EsriIconButton";
import { Root, createRoot } from "react-dom/client";

export function useMapExtentFilter({
  view,
  onClick,
}: {
  view: __esri.MapView | null;
  onClick: () => void;
}) {
  const divRef = useRef<HTMLDivElement>(document.createElement("div"));
  const [isMounted, setIsMounted] = useState<boolean>(false);
  const rootRef = useRef<Root>();

  useEffect(() => {
    if (view) {
      if (!isMounted) {
        view.ui.add(divRef.current, "top-right");
        setIsMounted(true);
      }
      if (!rootRef.current) {
        rootRef.current = createRoot(divRef.current);
      }
      rootRef.current.render(
        <EsriIconButton
          onClick={onClick}
          icon={"esri-icon-maps"}
          fallbackText="Filter by map extent"
        />
      );
    }
  }, [view, isMounted, onClick]);

  useEffect(() => {
    return () => {
      // NOTE: see https://github.com/facebook/react/issues/25675
      setTimeout(() => {
        if (rootRef.current) {
          rootRef.current.unmount();
        }
      });
    };
  }, []);
}

export default useMapExtentFilter;
