export class ExtractCapabilityMissingError extends Error {
  layers: __esri.Layer[];

  constructor(layers: __esri.Layer[]) {
    super('Missing "Extract" capability');
    this.layers = layers;
    // Bug: https://www.dannyguo.com/blog/how-to-fix-instanceof-not-working-for-custom-errors-in-typescript/
    Object.setPrototypeOf(this, ExtractCapabilityMissingError.prototype);
  }
}

export class ExportStatusError extends Error {
  layers: __esri.Layer[];

  constructor(layers: __esri.Layer[]) {
    super("An error occurred while checking export status.");
    this.layers = layers;
    // Bug: https://www.dannyguo.com/blog/how-to-fix-instanceof-not-working-for-custom-errors-in-typescript/
    Object.setPrototypeOf(this, ExportStatusError.prototype);
  }
}

export class ExportTimeoutError extends Error {
  layers: __esri.Layer[];

  constructor(layers: __esri.Layer[]) {
    super(
      "The export request took too long to complete and has exceeded the max retry attempts"
    );
    this.layers = layers;
    // Bug: https://www.dannyguo.com/blog/how-to-fix-instanceof-not-working-for-custom-errors-in-typescript/
    Object.setPrototypeOf(this, ExportTimeoutError.prototype);
  }
}

export class ExportServerError extends Error {
  layers: __esri.Layer[];

  constructor(layers: __esri.Layer[], detail?: string) {
    super(detail || "Unknown error");
    this.layers = layers;
    // Bug: https://www.dannyguo.com/blog/how-to-fix-instanceof-not-working-for-custom-errors-in-typescript/
    Object.setPrototypeOf(this, ExportServerError.prototype);
  }
}

export class GDBFilenameTooLong extends Error {
  constructor() {
    super(
      "One or more layer names exceed 70 characters and cannot be exported to File Geodatabase."
    );
    // Bug: https://www.dannyguo.com/blog/how-to-fix-instanceof-not-working-for-custom-errors-in-typescript/
    Object.setPrototypeOf(this, GDBFilenameTooLong.prototype);
  }
}
