import React, { useRef } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { TypeaheadComponentProps } from "react-bootstrap-typeahead/types/components/Typeahead";
import ITypeahead from "react-bootstrap-typeahead/types/core/Typeahead";
/**
 * Wrapper around react-bootstrap-typeahead that automatically clears the input
 * on blur if no selection was made
 * NOTE: this only works for controlled components where selected values
 * are passed as props
 * @param props Typeahead props
 * @returns Typeahead react component
 */

function TypeaheadWrapper(props: TypeaheadComponentProps) {
  const typeaheadRef = useRef<ITypeahead>(null);
  return (
    <Typeahead
      {...props}
      ref={props.instanceRef || typeaheadRef}
      onBlur={
        props.onBlur
          ? props.onBlur
          : () => {
              if (!props.selected?.length) {
                typeaheadRef?.current?.clear();
              }
            }
      }
    />
  );
}

const WrappedTypeahead = React.forwardRef<
  React.Ref<ITypeahead>,
  TypeaheadComponentProps
>(function Wrapper(props, ref) {
  return (
    <TypeaheadWrapper instanceRef={ref as React.Ref<ITypeahead>} {...props} />
  );
});

export default WrappedTypeahead;
