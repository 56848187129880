import React, { useEffect } from "react";
import "bootstrap/scss/bootstrap.scss";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";
import "material-icons/iconfont/outlined.css";
import "./components/Typeahead/TypeAheadStyleFixes.scss";
import "./app.scss";
import { Header, Footer, NotFoundView, LinearProgress } from "components";
import { Route, Routes, useLocation } from "react-router-dom";
import Home from "apps/Home";
import { useAppState } from "AppProvider";
import { Alert } from "react-bootstrap";
import gaTriggerEvent from "components/gaTriggerEvent";
// import InDevView from "components/InDevView";

const Admin = React.lazy(() => import("apps/Admin"));
const DataGlossary = React.lazy(() => import("apps/DataGlossary"));
const DataDownload = React.lazy(() => import("apps/DataDownload"));
const MapLibrary = React.lazy(() => import("apps/MapLibrary"));
const MapViewer = React.lazy(() => import("apps/MapViewer"));
const Analytics = React.lazy(() => import("apps/Analytics"));

export function App() {
  const { user, error, showLoading, isAuthenticating } = useAppState();
  const location = useLocation();

  useEffect(() => {
    gaTriggerEvent("page_view");
  }, [location.pathname]);

  return (
    <>
      <Header />
      {user ? (
        <section className="main position-relative">
          <LinearProgress isLoading={showLoading} />
          <React.Suspense fallback={<LinearProgress />}>
            <Routes>
              <Route path="/" element={<Home />}></Route>
              <Route path="/analytics/*" element={<Analytics />}></Route>
              <Route path="/map" element={<MapViewer />}></Route>
              <Route
                path="/map/:shareType/:id"
                element={
                  // wrapping in div to force new render when navigating from PID/SID to /map
                  <div className="h-100">
                    <MapViewer />
                  </div>
                }
              ></Route>
              {/* next route is for /rid?q=123  */}
              <Route
                path="/map/:shareType"
                element={
                  // wrapping in div to force new render when navigating from RID to /map
                  <div className="h-100">
                    <MapViewer />
                  </div>
                }
              ></Route>
              <Route path="/map-library" element={<MapLibrary />} />
              <Route path="/data/download" element={<DataDownload />} />
              <Route path="/data/glossary" element={<DataGlossary />} />
              <Route path="/admin/*" element={<Admin />} />
              <Route path="*" element={<NotFoundView />} />
            </Routes>
          </React.Suspense>
        </section>
      ) : error ? (
        <section className="main text-center m-5">
          <Alert variant="danger">{error}</Alert>
        </section>
      ) : (
        <section className="main text-center pt-5 position-relative">
          {isAuthenticating ? (
            <>
              <LinearProgress absolute />
              <div>Signing in...</div>
            </>
          ) : (
            <>Loading...</>
          )}
        </section>
      )}

      <Footer />
    </>
  );
}

export default App;
