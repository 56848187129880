import React, { useEffect, useRef, useState } from "react";
import { Spinner } from "react-bootstrap";
import { debounce } from "lodash";

export enum PromiseLoaderStatus {
  none,
  pending,
  done,
  error,
}

type Children = JSX.Element | JSX.Element[];

interface IChildrenGeneratorOpts<T> {
  value: T | undefined;
  status: PromiseLoaderStatus;
  error: unknown | undefined;
  Components: {
    Loader: React.FC;
    Error: React.FC;
  };
}

interface IPromiseLoaderParams<T> {
  children?: Children | ((opts: IChildrenGeneratorOpts<T>) => Children);
  customErrorMsg?: string;
  promise?: Promise<T> | null;
  showLoading?: boolean;
  showLoadingLabel?: string | null;
  showLoadingLabelClassNames?: string;
  showError?: boolean;
  showErrorClassNames?: string;
  showErrorTimeoutMs?: number | null;
  spinnerSize?: "sm";
  spinnerVariant?: string;
}

export function PromiseLoader<T>({
  children,
  customErrorMsg = "Failed",
  promise,
  showLoading,
  showLoadingLabel = "Loading...",
  showLoadingLabelClassNames = "",
  showError,
  showErrorClassNames = "",
  showErrorTimeoutMs,
  spinnerSize,
  spinnerVariant = "dark",
}: IPromiseLoaderParams<T>) {
  const [cancelled, setCancelled] = useState(false);
  const [status, setStatus] = useState<PromiseLoaderStatus>(
    PromiseLoaderStatus.none
  );
  const [value, setValue] = useState<T | undefined>();
  const [error, setError] = useState<unknown | undefined>();

  const onLoad = useRef(
    debounce((val) => {
      if (cancelled) return;
      setValue(val);
      setStatus(PromiseLoaderStatus.done);
    }, 500)
  );

  const onError = useRef(
    debounce((err: unknown) => {
      if (cancelled) return;
      setStatus(PromiseLoaderStatus.error);
      setError(err);
    }, 500)
  );

  useEffect(() => {
    return function cleanup() {
      setCancelled(true);
    };
  }, []);

  useEffect(() => {
    if (!promise) return;

    setStatus(PromiseLoaderStatus.pending);
    setError(undefined);

    // Cancel any existing pending statuses
    onLoad.current.cancel();

    // Load new promise
    promise.then(onLoad.current).catch(onError.current);
  }, [promise]);

  useEffect(() => {
    const ensureError = () => status === PromiseLoaderStatus.error;

    let timeout: NodeJS.Timeout;
    if (showErrorTimeoutMs && ensureError()) {
      timeout = setTimeout(() => {
        if (ensureError()) {
          setStatus(PromiseLoaderStatus.none);
          setError(undefined);
        }
      }, showErrorTimeoutMs);
    }

    return function cleanup() {
      if (timeout) clearTimeout(timeout);
    };
  }, [showErrorTimeoutMs, status]);

  const Loader = () => (
    <span>
      <Spinner
        animation="border"
        role="status"
        size={spinnerSize}
        variant={spinnerVariant}
      >
        <span className="visually-hidden">{showLoadingLabel || "Loading"}</span>
      </Spinner>
      {showLoadingLabel && (
        <span className={`ms-2 ${showLoadingLabelClassNames}`}>
          {showLoadingLabel}
        </span>
      )}
    </span>
  );

  const Error = () => (
    <span className={`text-danger ${showErrorClassNames}`}>
      <span className="material-icons-outlined align-text-top">error</span>
      <span className="ms-1">{customErrorMsg}</span>
    </span>
  );

  return (
    <>
      {status === PromiseLoaderStatus.pending && showLoading && <Loader />}
      {status === PromiseLoaderStatus.error && showError && <Error />}
      {status === PromiseLoaderStatus.none ||
      status === PromiseLoaderStatus.done ||
      (status === PromiseLoaderStatus.pending && !showLoading) ||
      (status === PromiseLoaderStatus.error && !showError)
        ? typeof children === "function"
          ? children({
              status,
              value,
              error,
              Components: {
                Loader,
                Error,
              },
            })
          : children
        : null}
    </>
  );
}
