import { ICellRendererParams } from "ag-grid-community";
// import { Spinner } from "components";
import React from "react";
import { Button } from "react-bootstrap";

interface ICellRendererCustomParams extends ICellRendererParams {
  onClick: () => void;
  url: string;
}

function LinksAndMetadataRenderer(params: ICellRendererCustomParams) {
  return (
    <>
      <Button
        as="a"
        href={params.url}
        target="_blank"
        size="sm"
        variant="link"
        title={params.url}
      >
        <span className="material-icons-outlined">article</span>
      </Button>
    </>
  );
}

export default React.memo(LinksAndMetadataRenderer);
