import React, { useCallback, useState } from "react";
import { Button, Form, InputGroup, Spinner } from "react-bootstrap";

enum CopyState {
  none,
  pending,
  success,
  error,
}

export default function CopyUrl({ url }: { url: string }) {
  const [copyState, setCopyState] = useState<CopyState>(CopyState.none);

  const copyLink = useCallback(async () => {
    try {
      setCopyState(CopyState.pending);
      await navigator.clipboard.writeText(url);
      setCopyState(CopyState.success);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      setCopyState(CopyState.error);
    }
  }, [url]);

  return (
    <>
      {url ? (
        <InputGroup className="mt-3">
          <Form.Control
            type="text"
            placeholder={`${window.origin}/map/sid/ZNrAjx`}
            readOnly
            value={url}
          />

          <Button
            variant="outline-secondary"
            title="Copy"
            size="sm"
            onClick={copyLink}
          >
            {copyState === CopyState.success ? (
              <span className="material-icons-outlined align-middle text-success">
                check_circle
              </span>
            ) : copyState === CopyState.error ? (
              <span className="material-icons-outlined align-middle text-danger">
                error
              </span>
            ) : copyState === CopyState.pending ? (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            ) : (
              <span className="material-icons-outlined align-middle">
                content_copy
              </span>
            )}
          </Button>
        </InputGroup>
      ) : null}
    </>
  );
}
