import { useCallback, useEffect, useRef, useState } from "react";
import { setAssetPath } from "@esri/calcite-components/dist/components";
import OAuthInfo from "@arcgis/core/identity/OAuthInfo";
import esriId from "@arcgis/core/identity/IdentityManager";
import esriConfig from "@arcgis/core/config";
import Portal from "@arcgis/core/portal/Portal";
import { IConfig } from "types/Config";
import { CookieStorage } from "../storage";

const isIwa = process.env.AUTH_ENV === "iwa";

// esriConfig.assetsPath = "./assets";
setAssetPath(window.location.origin);

function getCookieKey() {
  return "jfklasdl";
}

export function usePortalAuth(config: IConfig | null) {
  const cookieStorage = useRef(CookieStorage);
  const [user, setUser] = useState<__esri.PortalUser | null>(null);
  const [portal, setPortal] = useState<__esri.Portal | null>(null);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [isSuperAdmin, setIsSuperAdmin] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState("");

  const handlePortalLoad = useCallback(() => {
    const portal = new Portal();
    setIsLoading(true);
    portal
      .load()
      .then(async () => {
        portal.user
          .fetchGroups()
          .then((groups) => {
            groups.forEach((g) => {
              if (g.id === config?.portal?.adminGroupId) {
                setIsAdmin(true);
              }
              if (g.id === config?.portal?.superAdminGroupId) {
                setIsSuperAdmin(true);
              }
            });
          })
          .catch((e) => {
            setError(e.message);
          });
        const cred = await esriId.getCredential(
          config?.portal.url + "/sharing/rest"
        );
        const remaining = cred.expires - new Date().getTime() - 60000;

        if (remaining > 0) {
          setTimeout(async () => {
            await cred.refreshToken();
            cookieStorage.current.save(getCookieKey(), cred);
          }, remaining);
        }

        cookieStorage.current.save(getCookieKey(), cred);
        setUser(portal.user);
        setPortal(portal);
      })
      .catch((e) => {
        setError(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [config]);

  useEffect(() => {
    if (config?.portal) {
      esriConfig.portalUrl = config.portal.url;
      if (!isIwa) {
        const oAuthInfo = new OAuthInfo({
          appId: config.portal.appId,
          portalUrl: config.portal.url,
        });
        esriId.registerOAuthInfos([oAuthInfo]);
      }

      const url = config.portal.url + "/sharing/rest";
      esriId
        .checkSignInStatus(url)
        // user signed in, spin up portal and get user
        .then(handlePortalLoad)
        .catch(async () => {
          // user not signed in, check cookie
          const token = cookieStorage.current.get(
            getCookieKey()
          ) as __esri.IdentityManagerRegisterTokenProperties;
          if (token) {
            // try and use the stored token
            esriId.registerToken(token);
          }
          await esriId.getCredential(url);
          esriId
            .checkSignInStatus(url)
            .then(handlePortalLoad)
            .catch(async () => {
              // failed to register token, destroy creds and
              // call getCredential to re-prompt login
              esriId.destroyCredentials();
              cookieStorage.current.remove(getCookieKey());
              await esriId.getCredential(url);
            });
        });
    }
  }, [config, handlePortalLoad]);

  const signout = useCallback(() => {
    esriId.destroyCredentials();
    cookieStorage.current.remove(getCookieKey());
  }, []);

  return {
    isAdmin,
    isSuperAdmin,
    isLoading,
    user,
    portal,
    error,
    setError,
    signout,
  };
}

export default usePortalAuth;
