import React from "react";
import { Spinner as RBSpinner } from "react-bootstrap";
import "./spinner.scss";

const Spinner = ({
  children,
  className,
  size,
  style,
}: {
  children?: React.ReactNode;
  className?: string;
  size?: "sm";
  style?: React.CSSProperties;
}) => {
  return (
    <div
      style={style}
      className={`${
        className ? `${className} ` : ""
      }d-flex flex-column justify-content-center align-items-center spinner-fadein`}
    >
      <RBSpinner size={size} animation="border" role="status">
        <span className="visually-hidden">Loading...</span>
      </RBSpinner>
      {children}
    </div>
  );
};

export default Spinner;
