export enum LayerFilterQueryOperator {
  is = "is",
  isNot = "isNot",
  isBlank = "isBlank",
  isNotBlank = "isNotBlank",

  // String
  startsWith = "startsWith",
  endsWith = "endsWith",
  contains = "contains",
  doesNotContain = "doesNotContain",

  // Numeric
  isAtLeast = "isAtLeast",
  isAtMost = "isAtMost",
  isLessThan = "isLessThan",
  isGreaterThan = "isGreaterThan",

  // Date
  isBefore = "isBefore",
  isAfter = "isAfter",
  isBetween = "isBetween",
  isNotBetween = "isNotBetween",
}
