import { useCallback, useEffect, useRef, useState } from "react";
import Sketch from "@arcgis/core/widgets/Sketch";
import GraphicsLayer from "@arcgis/core/layers/GraphicsLayer";
import Expand from "@arcgis/core/widgets/Expand";
import "./sketch.scss";

interface SketchCreateTools {
  point?: boolean;
  polyline?: boolean;
  polygon?: boolean;
  rectangle?: boolean;
  circle?: boolean;
}

interface SketchSelectionTools {
  "lasso-selection"?: boolean;
  "rectangle-selection"?: boolean;
}

interface SketchVisibleElementOpts {
  createTools?: SketchCreateTools;
  selectionTools?: SketchSelectionTools;
  settingsMenu?: boolean;
  undoRedoMenu?: boolean;
}

const defaultOpts = {
  createTools: {
    point: false,
    polyline: false,
  },
  selectionTools: {
    "lasso-selection": false,
    "rectangle-selection": false,
  },
  settingsMenu: false,
  undoRedoMenu: false,
};

function useEsriSketch({
  view,
  // map,
  visibleElements = defaultOpts,
  location = "top-right",
  creationMode = "single",
  group,
  onCreate,
  defaultCreateOptions,
}: {
  view: __esri.MapView | null;
  visibleElements?: SketchVisibleElementOpts;
  location?: "top-right" | "top-left" | "bottom-right" | "bottom-left";
  creationMode?: "continuous" | "single" | "update";
  group?: string;
  onCreate?: (_g: __esri.Graphic[]) => void;
  defaultCreateOptions?: { mode?: "freehand" | "hybrid" | "click" };
}) {
  const graphicsLayerRef = useRef(
    new GraphicsLayer({ title: "Sketch Graphics" })
  );
  const [graphics, setGraphics] = useState<__esri.Graphic[] | null>(null);

  const sketchRef = useRef<__esri.Sketch | null>();
  const createHandle = useRef<__esri.Handle | null>();
  const expandRef = useRef<__esri.Expand | null>();

  const cleanup = () => {
    if (createHandle.current) {
      createHandle.current.remove();
      createHandle.current = null;
    }
    if (sketchRef.current) {
      sketchRef.current.destroy();
      sketchRef.current = null;
    }
    if (expandRef.current) {
      expandRef.current.destroy();
    }
  };

  useEffect(() => {
    if (view) {
      cleanup();
      sketchRef.current = new Sketch({
        layer: graphicsLayerRef.current,
        view: view,
        visibleElements,
        creationMode,
        defaultCreateOptions: defaultCreateOptions ?? { mode: "hybrid" },
      });

      // set graphics when create is completed
      createHandle.current = sketchRef.current.on("create", (e) => {
        if (e.state === "complete") {
          const gs = graphicsLayerRef.current.graphics.toArray();
          setGraphics(gs);
          onCreate && onCreate(gs);
          sketchRef.current?.layer.removeAll();
        }
      });
      const expand = new Expand({
        view,
        content: sketchRef.current,
        expandTooltip: "Filter by polygon",
        group,
      });
      expandRef.current = expand;
      // set default draw to polygon on expand
      expand.watch("expanded", (expanded) => {
        if (sketchRef.current) {
          if (expanded) {
            sketchRef.current.create("polygon", defaultCreateOptions);
          } else {
            sketchRef.current.cancel();
          }
        }
      });
      view.ui.add(expand, {
        position: location,
        index: -1,
      });
    }
  }, [
    view,
    visibleElements,
    location,
    creationMode,
    group,
    onCreate,
    defaultCreateOptions,
  ]);

  useEffect(() => {
    return cleanup;
  }, []);

  return {
    sketch: sketchRef.current,
    graphics,
    reset: useCallback((graphics?: __esri.Graphic[]) => {
      if (sketchRef.current) {
        sketchRef.current.layer.removeAll();
        if (graphics) {
          sketchRef.current.layer.addMany(graphics);
        }
        setGraphics(graphics || []);
      }
    }, []),
  };
}

export default useEsriSketch;
