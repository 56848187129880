import React, { useEffect, useState } from "react";
import Typeahead from "components/Typeahead";
// import { Typeahead } from "react-bootstrap-typeahead";
import { Form } from "react-bootstrap";
import { v4 as genId } from "uuid";
import { LayerFilterQueryOperator, LayerFilterSupportedLayers } from "../types";

interface ILayerFilterQueryTextInputParams {
  disabled?: boolean;
  layer?: LayerFilterSupportedLayers;
  field?: __esri.Field;
  prefill?: boolean;
  operator?: LayerFilterQueryOperator;
  value?: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: string) => any;
}

export default function LayerFilterQueryTextInput({
  disabled,
  layer,
  field,
  operator,
  value: inputValue,
  onChange,
}: ILayerFilterQueryTextInputParams) {
  const [uid] = useState(genId());
  const [typeaheadValue, setTypeaheadValue] = useState<string[]>([]);
  const [value, setValue] = useState<string>("");

  const [options, setOptions] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const [prefill, setPrefill] = useState(false);

  useEffect(() => {
    if (typeof inputValue !== "undefined") {
      setValue(inputValue.toString());
      setTypeaheadValue([inputValue.toString()]);
    }
  }, [inputValue]);

  useEffect(() => {
    setPrefill(
      operator
        ? [
            LayerFilterQueryOperator.is,
            LayerFilterQueryOperator.isNot,
          ].includes(operator)
        : false
    );
  }, [operator]);

  useEffect(() => {
    if (!prefill || !layer || !field) return;

    setLoading(true);
    layer
      .queryFeatures({
        outFields: [field.name],
        where: "1=1",
        returnDistinctValues: true,
      })
      .then((results) => {
        setOptions(
          results.features
            .map((feature) => feature.attributes[field.name])
            .filter((value) => value && !!String(value).trim())
            .sort()
        );
      })
      // eslint-disable-next-line no-console
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [field, layer, prefill]);

  return prefill && layer && field ? (
    <Typeahead
      id={`layer-filter-query-value-typeahead-${uid}`}
      clearButton
      placeholder="Choose a value"
      disabled={disabled}
      labelKey={(value) => value.toString()}
      isLoading={loading}
      options={options}
      onChange={([option]) => {
        setTypeaheadValue(option ? [option as string] : []);
        if (onChange) {
          onChange(`${option ?? ""}`);
        }
      }}
      selected={typeaheadValue}
    />
  ) : (
    <Form.Control
      type="text"
      required
      placeholder="Value"
      disabled={disabled}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        if (onChange) {
          onChange(e.target.value);
        }
      }}
    />
  );
}
