/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { LayerFilterQuery } from "components";
import {
  Button,
  Col,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip,
} from "react-bootstrap";
import { v4 as genId } from "uuid";
import { ILayerFilterQuery } from "./types/ILayerFilterQuery";
import { generateQuery } from "./utils";
import {
  LayerFilterQueryConditional,
  LayerFilterSupportedLayers,
} from "./types";
import FeatureLayer from "@arcgis/core/layers/FeatureLayer";

type OptionalPromise<T> = T | Promise<T>;
type LoadInitialDataFn = (
  layer: LayerFilterSupportedLayers
) => OptionalPromise<ILayerFilterQuery[] | null | undefined>;

interface ILayerFilterParams {
  /**
   * Base filter that is maintained on the layer
   */
  baseFilter?: string;
  /**
   * Class list to apply to the root element
   */
  className?: string;
  /**
   * Button text
   */
  btnText?: {
    add?: string;
    apply?: string;
    clear?: string;
  };
  /**
   * fields to exclude from the field dropdown select
   */
  ignoreFields?: string[];
  /**
   * The filter
   */
  initialValue?: ILayerFilterQuery[] | LoadInitialDataFn;
  /**
   * The esri layer to query against
   */
  layer?: LayerFilterSupportedLayers;
  /**
   * Prevents the filter from being applied to the map layer. This
   * is useful if you want to just return the query results.
   */
  disableLayerDefinition?: boolean;
  /**
   * Prevents displaying the result count in the UI
   */
  showResultCount?: boolean;
  /**
   * Callback for when a definitionExpression has been cleared.
   */
  onClear?: (layer: LayerFilterSupportedLayers) => any;
  /**
   * Callback for when a search has been successfully resolved.
   */
  onSearch?: (
    layer: LayerFilterSupportedLayers,
    queries: ILayerFilterQuery[],
    parsed: string
  ) => any;
  spatialFilter?: __esri.Geometry;
}

export default function LayerFilter({
  baseFilter,
  className,
  ignoreFields,
  initialValue,
  layer,
  disableLayerDefinition,
  onClear,
  onSearch,
  btnText,
  showResultCount = true,
  spatialFilter,
}: ILayerFilterParams) {
  const [queries, setQueries] = useState<ILayerFilterQuery[]>([
    {
      uid: genId(),
    },
  ]);
  const [loading, setLoading] = useState(false);
  const [querying, setQuerying] = useState(false);
  const [queryError, setQueryError] = useState("");
  const [resultCount, setResultCount] = useState<number | null>(null);

  useEffect(() => {
    setResultCount(null);
  }, [layer]);

  // if no queries set, reset result count
  useEffect(() => {
    if (queries.length === 1) {
      if (!queries[0].field && !queries[0].value) {
        setResultCount(null);
      }
    }
  }, [queries]);

  useEffect(() => {
    const load = async () => {
      setQueryError("");

      if (layer && typeof initialValue === "function") {
        try {
          setLoading(true);
          const q = await initialValue(layer);
          setQueries(
            q ?? [
              {
                uid: genId(),
              },
            ]
          );
        } catch (err) {
          // eslint-disable-next-line no-console
          console.error(err);
        } finally {
          setLoading(false);
        }
      } else if (layer && Array.isArray(initialValue)) {
        // Clone values -- no refs
        setQueries(initialValue.map((f) => ({ ...f })));
      } else {
        setQueries([{ uid: genId() }]);
      }
    };

    load();
  }, [initialValue, layer]);

  return (
    <div className={className}>
      {resultCount !== null && showResultCount && (
        <div>{resultCount.toLocaleString()} results</div>
      )}

      {loading ? (
        <div className="text-center mb-3">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        queries.map((q, index) => (
          <LayerFilterQuery
            ignoreFields={ignoreFields}
            key={q.uid}
            className="mb-3"
            layer={layer}
            additionalQuery={index > 0}
            query={q}
            onChange={(q) => {
              const dup = [...queries];
              dup[index] = q;
              setQueries(dup);
            }}
            onRemove={
              queries.length > 1
                ? () => {
                    const dup = [...queries];
                    dup.splice(index, 1);
                    setQueries(dup);
                  }
                : undefined
            }
          />
        ))
      )}

      <Row>
        <Col>
          <Button
            variant="primary"
            title="Apply"
            onClick={() => {
              async function fetch() {
                if (!layer) return;

                let q = "";
                try {
                  setQuerying(true);
                  q = await generateQuery(layer, queries);
                  const defExp = baseFilter ? `(${baseFilter}) AND (${q})` : q;
                  if (!disableLayerDefinition) {
                    // Filter items on map
                    layer.definitionExpression = defExp;
                  }
                  // Get result count
                  let count = null;
                  if (showResultCount) {
                    if (layer instanceof FeatureLayer) {
                      count = await layer.queryFeatureCount({
                        where: defExp,
                        geometry: spatialFilter,
                      });
                      // if map service sublayer
                    } else if (layer.createFeatureLayer) {
                      const fl = await layer.createFeatureLayer();
                      count = await fl.queryFeatureCount({
                        where: defExp,
                        geometry: spatialFilter,
                      });
                    }
                    setResultCount(count);
                  }

                  setQueryError("");
                  if (onSearch) onSearch(layer, queries, q);
                } catch (err) {
                  // eslint-disable-next-line no-console
                  console.error("Query", `[${q}]`, "Error:", err);
                  setQueryError("Failed to get results");
                } finally {
                  setQuerying(false);
                }
              }

              fetch();
            }}
          >
            {querying ? (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                as="span"
                className="me-1"
              >
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            ) : queryError ? (
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={<Tooltip>{queryError}</Tooltip>}
              >
                <span className="material-icons-outlined align-top text-warning">
                  warning
                </span>
              </OverlayTrigger>
            ) : (
              ""
            )}
            {btnText?.apply || "Apply"}
          </Button>
        </Col>
        <Col xs="auto">
          <Button
            variant="success"
            title="Add"
            onClick={() => {
              setQueries([
                ...queries,
                {
                  uid: genId(),
                  conditional: LayerFilterQueryConditional.and,
                },
              ]);
            }}
          >
            {btnText?.add || "Add"}
          </Button>
          <Button
            variant="danger"
            title="Clear"
            onClick={() => {
              if (layer && !disableLayerDefinition) {
                // Clear map filter
                layer.definitionExpression = baseFilter ?? "";
              }

              setQueries([
                {
                  uid: genId(),
                },
              ]);
              setQueryError("");
              setResultCount(null);

              if (layer && onClear) onClear(layer);
            }}
          >
            {btnText?.clear || "Clear"}
          </Button>
        </Col>
      </Row>
    </div>
  );
}
