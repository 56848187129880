import { useAppState } from "AppProvider";
import axios, {
  AxiosError,
  AxiosPromise,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import { useRef, useReducer, useEffect, useCallback } from "react";

interface Payload {
  req?: AxiosPromise;
  error?: string;
  data?: unknown;
}

type Status = "idle" | "pending" | "fulfilled" | "failed";
type Action =
  | { type: "FETCH_RESET"; payload: Payload | null }
  | { type: "FETCH_INIT"; payload: Payload }
  | { type: "FETCH_ABORT"; payload: Payload }
  | { type: "FETCH_SUCCESS"; payload: Payload }
  | { type: "FETCH_FAILURE"; payload: Payload };

export interface IFetchState<T = unknown> {
  status: Status;
  data?: T | null;
  fetches: Payload[];
  error: string;
}

const defaultState: IFetchState = {
  status: "idle",
  data: null,
  fetches: [],
  error: "",
};

function dataFetchReducer<T = unknown>() {
  return (state: IFetchState<T>, action: Action): IFetchState<T> => {
    const { error, data } = action.payload || {};
    const { fetches } = state;
    if (
      ["FETCH_ABORT", "FETCH_SUCCESS", "FETCH_FAILURE"].includes(action.type)
    ) {
      const currFetch = fetches?.find((f) => f.req === action.payload?.req);
      if (currFetch) {
        const idx = fetches.indexOf(currFetch);
        fetches.splice(idx, 1);
      }
    }
    switch (action.type) {
      case "FETCH_RESET":
        return {
          status: "idle",
          data: action.payload as T,
          fetches: [],
          error: "",
        };
      case "FETCH_INIT":
        return {
          ...state,
          status: "pending",
          data: null,
          fetches: [...state.fetches, action.payload],
          error: "",
        };
      case "FETCH_ABORT":
        return {
          ...state,
          status: "idle",
          data: null,
          fetches: [...fetches],
          // error: action.payload.res,
        };
      case "FETCH_SUCCESS":
        return {
          ...state,
          status: "fulfilled",
          data: action.payload.data as T,
          fetches: [...fetches],
          error: "",
        };
      case "FETCH_FAILURE":
        return {
          ...state,
          status: "failed",
          fetches: [...fetches],
          error: error || "",
          data: data as T,
        };
      default:
        throw new Error("Invalid reducer action type");
    }
  };
}

// hook for communicating with API that automatically appends the
// username to all outgoing request headers
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useAxios<T = any>({ abortPrevious = true } = {}) {
  const didCancel = useRef(false);
  const [state, dispatch] = useReducer(
    dataFetchReducer<T>(),
    defaultState as IFetchState<T>
  );
  const fetchAbortController = useRef<AbortController | null>(null);
  const { user } = useAppState();

  useEffect(() => {
    didCancel.current = false;
    return function cleanup() {
      didCancel.current = true;
    };
  }, []);
  const methods = {
    abort: useCallback(() => {
      if (fetchAbortController.current) {
        fetchAbortController.current.abort();
        fetchAbortController.current = null;
      }
    }, []),
    reset: useCallback(() => {
      if (!didCancel.current) {
        dispatch({ type: "FETCH_RESET", payload: null });
      }
    }, []),
    setDataState: useCallback((data: unknown) => {
      if (!didCancel.current) {
        dispatch({ type: "FETCH_SUCCESS", payload: { data } });
      }
    }, []),
    fetch: useCallback(
      async ({
        formatResponse = (data: T) => data,
        ...opts
      }: AxiosRequestConfig & { formatResponse?: (d: T) => T }): Promise<
        AxiosResponse<T>
      > => {
        //   include username on all outgoing API calls
        if (user) {
          if (opts.headers) {
            opts.headers["x-aims-username"] = user.username;
          } else {
            opts.headers = {
              "x-aims-username": user.username,
            };
          }
        }

        if (abortPrevious && fetchAbortController.current) {
          fetchAbortController.current.abort();
          fetchAbortController.current = null;
        }
        let req;
        try {
          fetchAbortController.current = new window.AbortController();
          opts.signal = fetchAbortController.current.signal;
          req = axios(opts);
          dispatch({
            type: "FETCH_INIT",
            payload: { req },
          });
          const res = await req;
          if (!didCancel.current) {
            dispatch({
              type: "FETCH_SUCCESS",
              payload: { req, data: formatResponse(res.data) },
            });
          }
          return res as AxiosResponse<T>;
        } catch (e) {
          if (e instanceof DOMException) {
            if (e.name === "AbortError") {
              if (!didCancel.current) {
                dispatch({
                  type: "FETCH_ABORT",
                  payload: {
                    req,
                    error: e.message,
                    data: null,
                  },
                });
              }
              throw e;
            }
          }
          if (e instanceof AxiosError) {
            if (!didCancel.current) {
              dispatch({
                type: "FETCH_FAILURE",
                payload: {
                  req,
                  data: null,
                  error:
                    e.response?.data?.detail ||
                    e.response?.data?.title ||
                    e.message,
                },
              });
            }
            throw e;
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          return e as any;
        }
      },
      [abortPrevious, user]
    ),
  };

  return { state, ...methods };
}

export default useAxios;
