import { ICellRendererParams, RowNode } from "ag-grid-community";
import React, { useState } from "react";

interface ICellRendererCustomParams extends ICellRendererParams {
  disabled: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, node: RowNode) => void;
}

function CheckboxRenderer({
  node,
  column,
  value,
  disabled,
  data,
  onChange,
}: ICellRendererCustomParams) {
  const [val, setVal] = useState(value);
  const checkedHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const colId = column?.getColId();
    if (colId) {
      node.setDataValue(colId, checked);
      setVal(checked);
    }
    if (onChange) {
      onChange(e, node);
    }
  };
  return (
    <>
      <input
        disabled={disabled || data.isLoading}
        type="checkbox"
        onChange={checkedHandler}
        checked={val}
      />
    </>
  );
}

export default React.memo(CheckboxRenderer);
