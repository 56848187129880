import React, { useEffect, useState } from "react";
import Typeahead from "components/Typeahead";
// import { Typeahead } from "react-bootstrap-typeahead";
import { v4 as genId } from "uuid";
import { LayerFilterQueryOperator } from "./types";
import { isDate, isDomain, isNumber } from "./utils";

interface IOperatorOption {
  text: string;
  value: LayerFilterQueryOperator;
}

export const COMMON_OPS: Array<IOperatorOption> = [
  { text: "is", value: LayerFilterQueryOperator.is },
  { text: "is not", value: LayerFilterQueryOperator.isNot },
  { text: "is blank", value: LayerFilterQueryOperator.isBlank },
  { text: "is not blank", value: LayerFilterQueryOperator.isNotBlank },
];

export const STRING_OPS: Array<IOperatorOption> = [
  ...COMMON_OPS,
  { text: "starts with", value: LayerFilterQueryOperator.startsWith },
  { text: "ends with", value: LayerFilterQueryOperator.endsWith },
  { text: "contains", value: LayerFilterQueryOperator.contains },
  { text: "does not contain", value: LayerFilterQueryOperator.doesNotContain },
];

export const STRING_DOMAIN_OPS: Array<IOperatorOption> = [...COMMON_OPS];

export const NUMBER_OPS: Array<IOperatorOption> = [
  ...COMMON_OPS,
  { text: "is at least", value: LayerFilterQueryOperator.isAtLeast },
  { text: "is at most", value: LayerFilterQueryOperator.isAtMost },
  { text: "is less than", value: LayerFilterQueryOperator.isLessThan },
  { text: "is greater than", value: LayerFilterQueryOperator.isGreaterThan },
];

export const DATE_OPS: Array<IOperatorOption> = [
  ...COMMON_OPS,
  { text: "is before", value: LayerFilterQueryOperator.isBefore },
  { text: "is after", value: LayerFilterQueryOperator.isAfter },
  { text: "is between", value: LayerFilterQueryOperator.isBetween },
  { text: "is not between", value: LayerFilterQueryOperator.isNotBetween },
];

interface ILayerFilterQueryOperatorSelectorParams {
  className?: string;
  field?: __esri.Field;
  value?: LayerFilterQueryOperator;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: LayerFilterQueryOperator | undefined) => any;
}

export default function LayerFilterQueryOperatorSelector({
  className,
  field,
  value,
  onChange,
}: ILayerFilterQueryOperatorSelectorParams) {
  const [uid] = useState(genId());
  const [operator, setOperator] = useState<IOperatorOption | undefined>();
  const [options, setOptions] = useState<IOperatorOption[]>(COMMON_OPS);

  useEffect(() => {
    setOptions(
      isDomain(field)
        ? STRING_DOMAIN_OPS
        : isDate(field)
        ? DATE_OPS
        : isNumber(field)
        ? NUMBER_OPS
        : STRING_OPS
    );
  }, [field]);

  useEffect(() => {
    const op = options.find((option) => option.value === value);
    setOperator(op);
  }, [options, value]);

  return (
    <Typeahead
      id={`layer-filter-operator-select-${uid}`}
      className={className}
      clearButton
      placeholder="Choose an operator"
      labelKey="text"
      options={options}
      onChange={([option]) => {
        const operator = option as IOperatorOption | undefined;
        setOperator(operator);

        if (onChange) {
          onChange(operator?.value);
        }
      }}
      selected={operator ? [operator] : []}
    />
  );
}
