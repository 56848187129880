import { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { v4 as genId } from "uuid";

export function ReportMapInterceptor() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  useEffect(() => {
    const x = Object.fromEntries([...searchParams.entries()]);
    const id = genId();
    window.localStorage.setItem(`rid:${id}`, JSON.stringify(x));
    navigate(`/map/rid?rid=${id}`);
  }, [navigate, searchParams, setSearchParams]);

  return null;
}
