import React from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import "./header.scss";
import imgSrc from "./aims-logo.png";
import { Link, useLocation } from "react-router-dom";
import { useAppState } from "AppProvider";

export interface IUser {
  name: string;
}

const iconProps = {
  style: { fontSize: "20px", lineHeight: "8px" },
  className: "esri-icon-handle-horizontal",
};

const Header = () => {
  const location = useLocation();
  const activePath = location.pathname;
  const {
    isAdmin,
    isSuperAdmin,
    user,
    getIsMobile,
    getIsTablet,
    config,
    showSidebar,
    setShowSidebar,
    signout,
  } = useAppState();
  const hasSidebar =
    activePath?.startsWith("/analytics") ||
    activePath === "/map" ||
    activePath?.includes("/map/rid") ||
    activePath?.includes("/map/sid") ||
    activePath?.includes("/map/pid");
  return (
    <Navbar variant="dark" expand="lg">
      <Container fluid className="px-3">
        {hasSidebar ? (
          <button
            className="me-3 navbar-toggler d-block"
            aria-label="Toggle sidebar"
            onClick={() => {
              setShowSidebar(!showSidebar);
            }}
          >
            <div className="d-flex flex-column">
              <span {...iconProps} />
              <span {...iconProps} />
              <span {...iconProps} />
            </div>
          </button>
        ) : null}
        <Navbar.Brand
          className={`d-flex align-items-center`}
          as={Link}
          to={"/"}
        >
          <>
            <span style={{ marginRight: "20px" }}>
              <img
                alt="COC logo"
                src={imgSrc}
                style={{
                  width: getIsMobile() || getIsTablet() ? "90px" : "120px",
                }}
              />
            </span>
            <h1 className="h5 m-0 d-none d-md-block">
              Asset Information Management System
            </h1>
          </>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className="justify-content-end" id="basic-navbar-nav">
          <Nav>
            <NavDropdown className="me-3" align="end" title="Apps">
              <NavDropdown.Item
                as={Link}
                to="/map-library"
                active={activePath === "/map-library"}
              >
                View Existing Maps
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to="/map"
                active={activePath === "/map"}
              >
                Make a Map
              </NavDropdown.Item>
              {!getIsMobile() || getIsTablet() ? (
                <>
                  <NavDropdown.Item
                    as={Link}
                    to="/data/glossary"
                    active={activePath === "/data/glossary"}
                  >
                    Data Glossary
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/data/download"
                    active={activePath === "/data/download"}
                  >
                    Access Data
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    as={Link}
                    to="/analytics"
                    active={activePath?.startsWith("/analytics")}
                  >
                    Analytics
                  </NavDropdown.Item>
                </>
              ) : null}
            </NavDropdown>
            <NavDropdown
              className="me-3"
              align="end"
              active={activePath === "/links"}
              title="Links"
            >
              <NavDropdown.Item
                href="https://dpsgateway-columbus.hub.arcgis.com"
                target="_blank"
              >
                DPS Gateway
              </NavDropdown.Item>
              <NavDropdown.Item
                href="https://aimshelpdev.cultivategeospatial.com/DPS_DistrictReport_2024.pdf"
                target="_blank"
              >
                District Report
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown className="me-3" align="end" title="Help">
              {getIsMobile() ? (
                <NavDropdown.Item href={config?.help.mobileUrl} target="_blank">
                  Mobile Documentation
                </NavDropdown.Item>
              ) : (
                <NavDropdown.Item href={config?.help.userUrl} target="_blank">
                  Documentation
                </NavDropdown.Item>
              )}
              <NavDropdown.Item
                href="https://app.smartsheet.com/b/form/e03ddd0d7a3c43c292fa1952119ee9c5"
                target="_blank"
              >
                Support Ticket
              </NavDropdown.Item>
            </NavDropdown>
            {isAdmin && (!getIsMobile() || getIsTablet()) ? (
              <NavDropdown
                className="me-3"
                align="end"
                active={activePath?.includes("/admin")}
                title="Admin"
              >
                <NavDropdown.Item
                  as={Link}
                  active={activePath === "/admin/newsfeed"}
                  to="/admin/newsfeed"
                >
                  News Feed
                </NavDropdown.Item>
                <NavDropdown.Item
                  as={Link}
                  active={activePath === "/admin/analytics-groups"}
                  to="/admin/analytics-groups"
                >
                  Analytics Groups
                </NavDropdown.Item>

                {isSuperAdmin ? (
                  <>
                    <NavDropdown.Item
                      as={Link}
                      active={activePath === "/admin/assettypes"}
                      to="/admin/assettypes"
                    >
                      Analytics Asset Types
                    </NavDropdown.Item>
                    <NavDropdown.Item
                      as={Link}
                      active={activePath === "/admin/map-links"}
                      to="/admin/map-links"
                    >
                      Analytics Map Links
                    </NavDropdown.Item>
                  </>
                ) : null}

                <NavDropdown.Item
                  as={Link}
                  active={activePath === "/admin/application-config"}
                  to="/admin/application-config"
                >
                  Application Configuration
                </NavDropdown.Item>
                <NavDropdown.Item href={config?.help.adminUrl} target="_blank">
                  Documentation
                </NavDropdown.Item>
              </NavDropdown>
            ) : null}
            {user ? (
              <>
                <NavDropdown
                  className="me-3"
                  align="end"
                  title={
                    <>
                      <span className="material-icons-outlined me-2">
                        account_circle
                      </span>
                      {user.fullName}
                    </>
                  }
                >
                  {
                    <>
                      <NavDropdown.Item
                        // as={Link}
                        onClick={() => {
                          signout();
                          window.location.reload();
                        }}
                        // to="/data/glossary"
                        // active={activePath === "/data/glossary"}
                      >
                        Sign out
                      </NavDropdown.Item>
                    </>
                  }
                </NavDropdown>
              </>
            ) : null}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
