import React, { useEffect, useState } from "react";
import Typeahead from "components/Typeahead";
// import { Typeahead } from "react-bootstrap-typeahead";
import { Form } from "react-bootstrap";
import { v4 as genId } from "uuid";

interface ILayerFilterQueryTextInputParams {
  disabled?: boolean;
  field?: __esri.Field;
  value?: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value?: string | number) => any;
}

export default function LayerFilterQueryDomainInput({
  disabled,
  field,
  value: inputValue,
  onChange,
}: ILayerFilterQueryTextInputParams) {
  const [uid] = useState(genId());
  const [value, setValue] = useState<string | number | undefined>();
  const [typeaheadValue, setTypeaheadValue] = useState<
    Array<__esri.CodedValue>
  >([]);

  useEffect(() => {
    if (typeof inputValue !== "undefined") {
      if (field?.domain?.type === "coded-value") {
        const val = field.domain.codedValues.find(
          (cv) => cv.code === inputValue
        );
        if (val) setTypeaheadValue([val]);
      } else {
        setValue(inputValue);
      }
    }
  }, [field, inputValue]);

  return field?.domain?.type === "coded-value" ? (
    <Typeahead
      id={`layer-filter-query-value-typeahead-${uid}`}
      clearButton
      placeholder="Choose a value"
      labelKey="name"
      disabled={disabled}
      options={field.domain.codedValues}
      onChange={([option]) => {
        const op = option as __esri.CodedValue | undefined;
        setValue(op?.code);
        setTypeaheadValue(op ? [op] : []);
        if (onChange) {
          onChange(op?.code);
        }
      }}
      selected={typeaheadValue}
    />
  ) : field?.domain?.type === "range" ? (
    <Form.Control
      type="number"
      required
      placeholder="Value"
      disabled={disabled}
      max={field.domain.maxValue}
      min={field.domain.minValue}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        if (onChange) {
          onChange(e.target.value);
        }
      }}
    />
  ) : (
    <Form.Control
      type="text"
      required
      placeholder="Value"
      disabled={disabled}
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        if (onChange) {
          onChange(e.target.value);
        }
      }}
    />
  );
}
