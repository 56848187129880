import React from "react";
import { Container, Row } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import "./footer.scss";

const Footer = () => {
  const location = useLocation();
  const path = location.pathname;
  // prevent footer on certain pages
  if (path.includes("/map") || path.startsWith("/analytics")) {
    return null;
  }
  return (
    <footer className="px-2 py-4">
      <Container fluid>
        <Row>
          <small>&copy;{new Date().getFullYear()} - City of Columbus</small>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
