import React from "react";

export default function EsriIconButton({
  icon,
  fallbackText,
  onClick,
}: {
  icon: string;
  fallbackText?: string;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}) {
  return (
    <button
      className={`border-0 no-styles esri-widget--button`}
      onClick={onClick}
      title={fallbackText}
    >
      <span aria-hidden="true" className={`esri-collapse__icon ${icon}`}></span>
      <span className="esri-icon-font-fallback-text">{fallbackText}</span>
    </button>
  );
}
