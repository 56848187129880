// NOTE: this is duplicated in webpack.config.js
const GA_MEASURE_ID =
  process.env.NODE_ENV === "production" ? "G-BDWMXK94VN" : "";

declare global {
  interface Window {
    gtag: (_e: string, _mid: string) => void;
  }
}

export default function gaTriggerEvent(event: string) {
  if (event && window && window.gtag) {
    return window.gtag(event, GA_MEASURE_ID);
  }
  return false;
}
