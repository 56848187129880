import { ICellRendererParams } from "ag-grid-community";
// import { Spinner } from "components";
import React from "react";
import { Button, Spinner } from "react-bootstrap";

interface ICellRendererCustomParams extends ICellRendererParams {
  showDelete: boolean;
  disableSave: boolean;
  isEditing: boolean;
}
const btnClasses = "d-flex align-items-center";
function actionCellRenderer(params: ICellRendererCustomParams) {
  // if (!params.data.id) {
  //   return (
  //     <>
  //       <Button size="sm" data-action="save" disabled={params.data.isLoading}>
  //         <span className="material-icons-outlined pe-none">save</span>
  //       </Button>
  //       <Button
  //         size="sm"
  //         variant="secondary"
  //         data-action="delete"
  //         disabled={params.data.isLoading}
  //       >
  //         <span className="material-icons-outlined pe-none">cancel</span>
  //       </Button>
  //     </>
  //   );
  // }
  // if (params.isEditing) {
  //   return (
  //     <Button
  //       size="sm"
  //       variant="secondary"
  //       data-action="delete"
  //       disabled={params.data.isLoading}
  //     >
  //       <span className="material-icons-outlined pe-none">cancel</span>
  //     </Button>
  //   );
  // }
  // if (params.showDelete) {
  //   return (
  //     <>
  //       <Button
  //         size="sm"
  //         variant="danger"
  //         data-action="delete"
  //         disabled={params.data.isLoading}
  //       >
  //         <span className="material-icons-outlined pe-none">
  //           delete_forever
  //         </span>
  //       </Button>
  //       {params.data.isLoading ? (
  //         <Spinner className="ms-3" animation="border" role="status" size="sm">
  //           <span className="visually-hidden">Loading...</span>
  //         </Spinner>
  //       ) : null}
  //     </>
  //   );
  // }

  return (
    <>
      {!params.data.id ? (
        <div className="d-flex align-items-center h-100">
          <Button
            className={btnClasses}
            size="sm"
            data-action="save"
            disabled={params.data.isLoading}
          >
            <span className="material-icons-outlined sm pe-none">save</span>
          </Button>
          <Button
            className={btnClasses}
            size="sm"
            variant="secondary"
            data-action="delete"
            disabled={params.data.isLoading}
          >
            <span className="material-icons-outlined sm pe-none ">cancel</span>
          </Button>
        </div>
      ) : (
        <div className="d-flex align-items-center h-100">
          {params.isEditing ? (
            <Button
              className={btnClasses}
              size="sm"
              variant="outline-success"
              data-action="delete"
              disabled={params.data.isLoading}
            >
              <span className="material-icons-outlined sm pe-none">done</span>
            </Button>
          ) : null}
          {params.showDelete && !params.isEditing ? (
            <>
              <Button
                className={btnClasses}
                size="sm"
                variant="danger"
                data-action="delete"
                disabled={params.data.isLoading}
              >
                <span className="material-icons-outlined sm pe-none">
                  delete_forever
                </span>
              </Button>
              {params.data.isLoading ? (
                <Spinner
                  className="ms-3"
                  animation="border"
                  role="status"
                  size="sm"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              ) : null}
            </>
          ) : null}
        </div>
      )}
    </>
  );
}

export default React.memo(actionCellRenderer);
