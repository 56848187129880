import React, { useCallback, useEffect, useRef, useState } from "react";
import { Root, createRoot } from "react-dom/client";
import {
  Alert,
  Button,
  FloatingLabel,
  Form,
  InputGroup,
} from "react-bootstrap";
import Expand from "@arcgis/core/widgets/Expand";
import debounce from "lodash.debounce";
import esriRequest from "@arcgis/core/request";
import Graphic from "@arcgis/core/Graphic";
import { Typeahead } from "react-bootstrap-typeahead";
import { ILRSConfig } from "types/Config";
import { ILRSRouteData } from "apps/Analytics/types/ILRSRouteData";
import { useLrsRoutes } from "apps/Analytics/api/useLrsRoutes";

interface ILogs {
  begLog: number | undefined;
  endLog: number | undefined;
}

interface DebouncedFunction extends Function {
  cancel(): void;
}

const NLFID_KEY = "route_name";
const BEGIN_LOG_KEY = "begin_log";
const END_LOG_KEY = "end_log";

function truncateDecimals(number: number, digits: number) {
  const re = new RegExp("(\\d+\\.\\d{" + digits + "})(\\d)"),
    m = number.toString().match(re);
  return m ? parseFloat(m[1]) : number.valueOf();
}

const polylineSymbol = {
  type: "simple-line",
  color: [0, 255, 255],
  width: 4,
};

function LRSFilterTool({
  view,
  onFilterChange,
  routes,
  urls,
  lrsExpression,
}: {
  view: __esri.MapView;
  onFilterChange: (filter: string, graphic?: __esri.Graphic) => void;
  routes: ILRSRouteData[];
  urls: ILRSConfig;
  lrsExpression?: string;
}) {
  const [minMaxLogs, setMinMaxLogs] = useState<ILogs>();
  const [logs, setLogs] = useState<ILogs>();
  const [nlfid, setNlfid] = useState<{
    nlfid: string;
    mmin: number;
    mmax: number;
  } | null>();
  const [clickActive, setClickActive] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [graphic, setGraphic] = useState<__esri.Graphic>();
  const getRouteDebouncedRef = useRef<DebouncedFunction | null>(null);

  const getExpression = useCallback(() => {
    return `${NLFID_KEY} = '${nlfid?.nlfid}' AND ${BEGIN_LOG_KEY} >= ${logs?.begLog} AND ${END_LOG_KEY} <= ${logs?.endLog}`;
  }, [logs?.begLog, logs?.endLog, nlfid?.nlfid]);

  const geometryToMeasure = useCallback(
    async ({
      longitude,
      latitude,
    }: {
      longitude: number;
      latitude: number;
    }) => {
      try {
        if (!urls.lrsToolsSoeUrl) {
          throw Error("URL isn't available");
        }
        // TODO: tolerance from config?
        const url = `${urls.lrsToolsSoeUrl}/measureAtPoint?f=json&x=${longitude}&y=${latitude}&inSR=4326&outSR=4326&tolerance=35`;
        const res = await esriRequest(url, { responseType: "json" });
        return res.data;
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error("Could not fetch route between measures.");
      }
    },
    [urls.lrsToolsSoeUrl]
  );

  const getRouteBetweenMeasures = useCallback(async () => {
    try {
      setLoading(true);
      const url = `${urls.lrsToolsSoeUrl}/routeBetweenMeasures?f=json&routeId=${nlfid?.nlfid}&fromMeasure=${logs?.begLog}&toMeasure=${logs?.endLog}&outSR=102100`;
      const res = await esriRequest(url, { responseType: "json" });
      const geometry = { ...res.data.features[0]?.geometry };
      geometry.type = "polyline";
      geometry.spatialReference = res.data.spatialReference;
      const polylineGraphic = new Graphic({
        geometry,
        symbol: {
          ...polylineSymbol,
          color:
            lrsExpression === getExpression()
              ? [0, 0, 0, 0.25]
              : polylineSymbol.color,
        },
      });
      setGraphic(polylineGraphic);
      view.graphics.removeAll();
      view.graphics.add(polylineGraphic);
      setLoading(false);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error("Could not fetch route between measures.");
    }
  }, [
    urls.lrsToolsSoeUrl,
    nlfid?.nlfid,
    logs?.begLog,
    logs?.endLog,
    lrsExpression,
    getExpression,
    view.graphics,
  ]);

  const enableClick = useCallback(async () => {
    setClickActive(true);
    view.container.style.cursor = "crosshair";
    const handler = view.on("click", async (e) => {
      setMessage("");
      setLoading(true);
      view.graphics.removeAll();
      setNlfid(null);
      const { latitude, longitude } = e.mapPoint;
      const res = (await geometryToMeasure({
        latitude,
        longitude,
      })) as __esri.FeatureSet;

      const ftr = res && res.features[0];
      if (ftr) {
        const opts = [ftr.attributes];
        // setOptions(opts);
        setNlfid(opts[0]);
        setLogs({
          begLog: truncateDecimals(opts[0].mmin, 3),
          endLog: truncateDecimals(opts[0].mmax, 3),
        });
        setMinMaxLogs({
          begLog: truncateDecimals(opts[0].mmin, 3),
          endLog: truncateDecimals(opts[0].mmax, 3),
        });
        // NOTE: getRouteBetweenMeasures handles adding the graphic
        // const polylineGraphic = new Graphic({
        //   geometry: ftr.geometry,
        //   symbol: polylineSymbol,
        // });
        // view.graphics.add(polylineGraphic);
        view.container.style.cursor = "default";
        setClickActive(false);
        handler.remove();
      } else {
        setMessage("No features found.");
      }
      setLoading(false);
    });
  }, [geometryToMeasure, view]);
  // Set the debounced search function.
  useEffect(() => {
    getRouteDebouncedRef.current = debounce(getRouteBetweenMeasures, 400);
    return () => {
      getRouteDebouncedRef.current && getRouteDebouncedRef.current.cancel();
    };
  }, [getRouteBetweenMeasures]);

  // get route between measures when logs change
  useEffect(() => {
    if ((logs?.begLog || logs?.begLog === 0) && logs?.endLog && nlfid) {
      getRouteDebouncedRef.current && getRouteDebouncedRef.current();
    }
  }, [logs, nlfid, view.graphics]);

  useEffect(() => {
    if (!lrsExpression) {
      setLogs(undefined);
      setNlfid(undefined);
      setGraphic(undefined);
      view.graphics.removeAll();
    } else {
      const route = lrsExpression
        .split(`${NLFID_KEY} = '`)
        .join("")
        ?.split("' AND")[0];
      const beg = lrsExpression
        .split(`${BEGIN_LOG_KEY} >= `)[1]
        .split(" AND")[0];
      const end = lrsExpression.split(`${END_LOG_KEY} <= `)[1];
      const routeObj = routes.find((r) => r.nlfid === route);
      setNlfid(routeObj);
      setMinMaxLogs({
        begLog: routeObj ? truncateDecimals(routeObj.mmin, 3) : undefined,
        endLog: routeObj ? truncateDecimals(routeObj.mmax, 3) : undefined,
      });
      setLogs({
        begLog: Number(beg),
        endLog: Number(end),
      });
    }
  }, [lrsExpression, routes, view.graphics]);

  return (
    <div
      className="px-3 pb-2 text-center"
      style={{ backgroundColor: "#fff", width: "300px" }}
    >
      <InputGroup className="mb-3 pt-3">
        <Button
          variant={clickActive ? "secondary" : "outline-secondary"}
          id="lrs-route"
          className="d-flex align-items-center"
          onClick={() => {
            if (!clickActive) {
              enableClick();
            }
          }}
        >
          <span className="material-icons-outlined">add</span>
        </Button>
        <Typeahead
          className="d-block"
          selected={nlfid ? (([nlfid] as unknown) as ILRSRouteData[]) : []}
          id="nlfid-search"
          isLoading={loading}
          labelKey={"nlfid"}
          onChange={(val) => {
            if (val.length === 0) return;
            const [route] = (val as unknown) as ILRSRouteData[]; //???
            setNlfid(route);
            setLogs({
              begLog: truncateDecimals(route.mmin, 3),
              endLog: truncateDecimals(route.mmax, 3),
            });
            setMinMaxLogs({
              begLog: truncateDecimals(route.mmin, 3),
              endLog: truncateDecimals(route.mmax, 3),
            });
          }}
          onInputChange={() => {
            setNlfid(null);
          }}
          options={routes}
          renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
            <FloatingLabel controlId="nlfid-input" label="NLFID">
              <Form.Control
                {...inputProps}
                ref={(node: HTMLInputElement) => {
                  inputRef(node);
                  referenceElementRef(node);
                }}
                value={(inputProps.value as string | number | undefined) || ""}
              />
            </FloatingLabel>
          )}
        />
      </InputGroup>
      <Form validated className="d-flex justify-content-center">
        <FloatingLabel
          className="flex-grow-1"
          controlId="begin-log-input"
          label="Begin Log"
        >
          <Form.Control
            type="number"
            disabled={!nlfid}
            step={0.001}
            min={minMaxLogs?.begLog || 0}
            max={minMaxLogs?.endLog}
            value={logs?.begLog || logs?.begLog === 0 ? logs?.begLog : ""}
            onChange={(e) => {
              setLogs({
                begLog: Number(e.target.value),
                endLog: logs?.endLog,
              });
            }}
          />
        </FloatingLabel>
        <FloatingLabel
          className="flex-grow-1"
          controlId="end-log-input"
          label="End Log"
        >
          <Form.Control
            type="number"
            disabled={!nlfid}
            step={0.001}
            min={minMaxLogs?.begLog || 0}
            max={minMaxLogs?.endLog}
            value={logs?.endLog || ""}
            onChange={(e) => {
              setLogs({
                begLog: logs?.begLog,
                endLog: Number(e.target.value),
              });
            }}
          />
        </FloatingLabel>
      </Form>
      <span className="mt-3 d-flex">
        <Button
          onClick={() => {
            onFilterChange(getExpression(), graphic);
          }}
        >
          Apply
        </Button>

        <Button
          size="sm"
          variant="outline-secondary"
          title="Zoom to"
          className="ms-auto d-flex align-items-center"
          onClick={() => {
            if (graphic) {
              view.goTo(graphic);
            }
          }}
        >
          <span className="material-icons-outlined md-light">zoom_in</span>
        </Button>
        <Button
          size="sm"
          title="Cancel"
          variant="outline-secondary"
          className="ms-1 d-flex align-items-center"
          onClick={() => {
            setLogs(undefined);
            setNlfid(undefined);
            setGraphic(undefined);
            view.graphics.removeAll();
            onFilterChange("");
          }}
        >
          <span className="material-icons-outlined md-light">clear</span>
        </Button>
      </span>
      {message ? (
        <Alert className="mt-3" variant="warning">
          {message}
        </Alert>
      ) : null}
    </div>
  );
}

export function useLrsFilter({
  view,
  group,
  onSetFilter,
  urls,
  // routes,
  lrsExpression,
}: {
  view: __esri.MapView | null;
  group?: string;
  onSetFilter?: (where?: string) => void;
  // routes: ILRSRouteData[];
  urls: ILRSConfig | null;
  lrsExpression?: string;
}) {
  const divRef = useRef<HTMLDivElement>(document.createElement("div"));
  const rootRef = useRef<Root>();
  const [activeFilter, setActiveFilter] = useState<string>();
  const [activeGraphic, setActiveGraphic] = useState<__esri.Graphic>();
  const routesQ = useLrsRoutes();

  useEffect(() => {
    let expand: __esri.Expand;
    if (view && urls) {
      expand = new Expand({
        view,
        content: divRef.current,
        expandTooltip: "Filter by NLFID",
        expandIconClass: "esri-icon-map-pin",
        group,
      });
      view.ui.add(expand, "top-right");
      if (!rootRef.current) {
        rootRef.current = createRoot(divRef.current);
      }
      rootRef.current.render(
        <LRSFilterTool
          urls={urls}
          onFilterChange={(f, graphic) => {
            setActiveFilter(f);
            setActiveGraphic(graphic);
            onSetFilter && onSetFilter(f);
          }}
          lrsExpression={lrsExpression}
          routes={routesQ.data ?? []}
          view={view}
        />
      );
    }
    return () => {
      if (expand) {
        expand.destroy();
      }
    };
  }, [view, group, urls, onSetFilter, lrsExpression, routesQ.data]);

  useEffect(() => {
    return () => {
      // NOTE: see https://github.com/facebook/react/issues/25675
      setTimeout(() => {
        if (rootRef.current) {
          rootRef.current.unmount();
        }
      });
    };
  }, []);

  return { activeFilter, activeGraphic };
}

export default useLrsFilter;
