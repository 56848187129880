export function isNumber(field?: __esri.Field) {
  const numberTypes: Array<__esri.Field["type"]> = [
    "single",
    "small-integer",
    "integer",
    "long",
    "double",
    "oid",
  ];

  return field ? numberTypes.includes(field.type) : false;
}

export function isDate(field?: __esri.Field) {
  const dateTypes: Array<__esri.Field["type"]> = ["date"];
  return field ? dateTypes.includes(field.type) : false;
}

export function isDomain(field?: __esri.Field) {
  return !!field?.domain;
}
