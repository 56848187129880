import React, { useEffect, useState } from "react";
import moment from "moment";
import { Form, InputGroup } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import { LayerFilterQueryOperator } from "../types";

const ESRI_TIMESTAMP_FORMAT = "MM/DD/YYYY HH:mm";

const formatEsriTimestamp = (t: Date) =>
  moment(t).utc().format(ESRI_TIMESTAMP_FORMAT);

const parseEsriTimestamp = (t: string) => moment.utc(t).local().toDate();

const genEmitValue = (t1?: Date | null, t2?: Date | null) => {
  let buffer = "";
  if (t1) buffer += `TIMESTAMP '${formatEsriTimestamp(t1)}'`;
  if (t2) buffer += ` AND TIMESTAMP '${formatEsriTimestamp(t2)}'`;
  return buffer;
};

interface ICustomDateTimePickerParams {
  disabled?: boolean;
  max?: Date | null;
  min?: Date | null;
  value: Date | null;
  onChange: (d: Date | null) => void;
}

function CustomDateTimePicker({
  disabled,
  max,
  min,
  value,
  onChange,
}: ICustomDateTimePickerParams) {
  return (
    <ReactDatePicker
      timeInputLabel="Time:"
      showTimeInput
      disabled={disabled}
      maxDate={max}
      minDate={min}
      selected={value}
      onChange={onChange}
      customInput={
        <InputGroup>
          <InputGroup.Text>
            <span className="material-icons-outlined">calendar_month</span>
          </InputGroup.Text>
          <Form.Control
            placeholder="Enter a time"
            disabled={disabled}
            value={value ? moment(value).format(ESRI_TIMESTAMP_FORMAT) : ""}
          />
        </InputGroup>
      }
    />
  );
}

interface ILayerFilterQueryDateInputParams {
  disabled?: boolean;
  operator?: LayerFilterQueryOperator;
  value?: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value: string) => any;
}

export default function LayerFilterQueryDateInput({
  disabled,
  operator,
  value,
  onChange,
}: ILayerFilterQueryDateInputParams) {
  const [firstDate, setFirstDate] = useState<Date | null>(null);
  const [secondDate, setSecondDate] = useState<Date | null>(null);

  useEffect(() => {
    if (!value) return;

    const parseTimestamps = /'([\d|/|:\s]+)'/gi;
    const results = Array.from(value.toString().matchAll(parseTimestamps));

    const d1 = results[0];
    if (d1) {
      const g1 = d1[1];
      setFirstDate(parseEsriTimestamp(g1));
    }

    const d2 = results[1];
    if (d2) {
      const g1 = d2[1];
      setSecondDate(parseEsriTimestamp(g1));
    }
  }, [value]);

  return (
    <>
      <CustomDateTimePicker
        disabled={disabled}
        value={firstDate}
        max={secondDate}
        onChange={(newDate) => {
          setFirstDate(newDate);
          if (onChange) {
            onChange(genEmitValue(newDate, secondDate));
          }
        }}
      />

      {operator &&
        [
          LayerFilterQueryOperator.isBetween,
          LayerFilterQueryOperator.isNotBetween,
        ].includes(operator) && (
          <div>
            <div className="my-2 text-center">
              <b>AND</b>
            </div>

            <CustomDateTimePicker
              disabled={disabled}
              value={secondDate}
              min={firstDate}
              onChange={(newDate) => {
                setSecondDate(newDate);
                if (onChange) {
                  onChange(genEmitValue(firstDate, newDate));
                }
              }}
            />
          </div>
        )}
    </>
  );
}
