import { useQuery } from "@tanstack/react-query";
import { useAppState } from "AppProvider";
import esriRequest from "@arcgis/core/request";

export const useLrsRoutes = () => {
  const { isAuthenticating, user, config: appConfig } = useAppState();

  const getRoutes = async () => {
    const url = `${appConfig?.lrs.lrsToolsSoeUrl}/routeMeasures?f=json`;
    const res = (await esriRequest(url, { responseType: "json" })) as {
      data: { routes: { NLFID: string; MMax: number; MMin: number }[] };
    };
    return res.data?.routes.map((r) => ({
      nlfid: r.NLFID,
      mmax: r.MMax,
      mmin: r.MMin,
    }));
  };

  return useQuery({
    queryKey: ["analytics-lrs-routes"],
    queryFn: getRoutes,
    staleTime: Infinity,
    enabled: !isAuthenticating && !!user && !!appConfig?.lrs.lrsToolsSoeUrl,
  });
};
