import { LayerFilterQueryOperator, LayerFilterSupportedLayers } from "../types";
import { ILayerFilterQuery } from "../types/ILayerFilterQuery";
import { isDate, isNumber } from "./fieldTypes";

export const LayerFilterQueryPatternField = "[FIELD]";
export const LayerFilterQueryPatternValue = "[VALUE]";

export const LayerFilterQueryPatterns: Record<
  LayerFilterQueryOperator,
  string
> = {
  [LayerFilterQueryOperator.is]: `${LayerFilterQueryPatternField} = ${LayerFilterQueryPatternValue}`,
  [LayerFilterQueryOperator.isNot]: `${LayerFilterQueryPatternField} <> ${LayerFilterQueryPatternValue}`,
  [LayerFilterQueryOperator.isBlank]: `${LayerFilterQueryPatternField} IS NULL`,
  [LayerFilterQueryOperator.isNotBlank]: `${LayerFilterQueryPatternField} IS NOT NULL`,
  [LayerFilterQueryOperator.startsWith]: `${LayerFilterQueryPatternField} ILIKE '${LayerFilterQueryPatternValue}%'`,
  [LayerFilterQueryOperator.endsWith]: `${LayerFilterQueryPatternField} ILIKE '%${LayerFilterQueryPatternValue}'`,
  [LayerFilterQueryOperator.contains]: `${LayerFilterQueryPatternField} ILIKE '%${LayerFilterQueryPatternValue}%'`,
  [LayerFilterQueryOperator.doesNotContain]: `${LayerFilterQueryPatternField} NOT ILIKE '%${LayerFilterQueryPatternValue}%'`,
  [LayerFilterQueryOperator.isAtLeast]: `${LayerFilterQueryPatternField} >= ${LayerFilterQueryPatternValue}`,
  [LayerFilterQueryOperator.isAtMost]: `${LayerFilterQueryPatternField} <= ${LayerFilterQueryPatternValue}`,
  [LayerFilterQueryOperator.isLessThan]: `${LayerFilterQueryPatternField} < ${LayerFilterQueryPatternValue}`,
  [LayerFilterQueryOperator.isGreaterThan]: `${LayerFilterQueryPatternField} > ${LayerFilterQueryPatternValue}`,
  [LayerFilterQueryOperator.isBefore]: `${LayerFilterQueryPatternField} < ${LayerFilterQueryPatternValue}`,
  [LayerFilterQueryOperator.isAfter]: `${LayerFilterQueryPatternField} > ${LayerFilterQueryPatternValue}`,
  [LayerFilterQueryOperator.isBetween]: `${LayerFilterQueryPatternField} BETWEEN ${LayerFilterQueryPatternValue}`,
  [LayerFilterQueryOperator.isNotBetween]: `${LayerFilterQueryPatternField} NOT BETWEEN ${LayerFilterQueryPatternValue}`,
};

export async function generateQuery(
  layer: LayerFilterSupportedLayers,
  queries: ILayerFilterQuery[]
): Promise<string> {
  if (layer.loadStatus === "not-loaded") {
    await layer.load();
  }

  const fieldLookup = layer.fields.reduce((dict, field) => {
    dict[field.name] = field;
    return dict;
  }, {} as Record<string, __esri.Field>);

  let query = "";

  for (const {
    conditional,
    field,
    operator,
    value,
    secondValue,
    secondOperator,
  } of queries) {
    if (
      typeof field === "undefined" ||
      typeof operator === "undefined" ||
      (typeof value === "undefined" &&
        operator !== "isBlank" &&
        operator !== "isNotBlank")
    ) {
      return query;
    }
    const template = LayerFilterQueryPatterns[operator];
    const fieldObj = fieldLookup[field];

    if (!template) throw new Error("Unknown query type");
    if (!field) throw new Error("Unkown field");

    if (conditional && !secondValue?.toString()) {
      query += " ";
      query += conditional;
      query += " ";
    }
    const formattedStringValue =
      operator === LayerFilterQueryOperator.is ||
      operator === LayerFilterQueryOperator.isNot
        ? formatFieldValue(fieldObj, value?.toString() || "")
        : value?.toString();

    query += template
      .replace(LayerFilterQueryPatternField, field)
      .replace(LayerFilterQueryPatternValue, formattedStringValue || "");

    if (secondOperator && conditional) {
      const template2 = LayerFilterQueryPatterns[secondOperator];
      const secondFormattedStringVal =
        secondOperator === LayerFilterQueryOperator.is ||
        secondOperator === LayerFilterQueryOperator.isNot
          ? formatFieldValue(fieldObj, secondValue?.toString() || "")
          : secondValue?.toString();
      const templated = template2
        .replace(LayerFilterQueryPatternField, field)
        .replace(LayerFilterQueryPatternValue, secondFormattedStringVal || "");
      query += ` ${conditional} ${templated}`;
    }
  }

  return query;
}

function formatFieldValue(field: __esri.Field, value: string) {
  return isDate(field) || isNumber(field) ? value : `'${value}'`;
}
