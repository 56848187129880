import { useEffect } from "react";
import useAxios from "./useAxios";
import config from "appconfig";

interface NewsFeed {
  id: string | number;
  message: string;
}

export function useNewsFeed() {
  const { fetch, state } = useAxios();
  const { fetch: save, state: saveState } = useAxios();
  // currently only supporting a single news feed item
  const [newsFeed] = state.data
    ? (state.data as NewsFeed[])
    : ([] as NewsFeed[]);

  useEffect(() => {
    fetch({
      url: `${config.apiBaseUrl}/admin/news`,
    });
  }, [fetch]);

  return {
    state,
    newsFeed,
    save: (data: NewsFeed) => {
      return save({
        url: `${config.apiBaseUrl}/admin/news`,
        data,
        method: "PUT",
      });
    },
    saveState,
  };
}

export default useNewsFeed;
