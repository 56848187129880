import React, {
  ForwardedRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { ICellEditorParams } from "ag-grid-community";
import { Form } from "react-bootstrap";

interface ICellEditorCustomParams extends ICellEditorParams {
  maxLength: number;
  requiredText?: string;
  type?: string;
  required?: boolean;
  focusInput?: boolean;
}

const CustomCellEditor = React.forwardRef(function RFE(
  {
    value,
    data,
    focusInput = true,
    maxLength,
    required,
    type,
  }: ICellEditorCustomParams,
  ref: ForwardedRef<HTMLInputElement>
) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [val, setVal] = useState(value);
  const [error, setError] = useState(data.error);

  useImperativeHandle(ref, () => {
    return ({
      getValue() {
        return val;
      },
    } as unknown) as HTMLInputElement;
  });

  // focus on the input
  useEffect(() => {
    if (focusInput) {
      const eInput = inputRef.current;
      eInput?.focus();
    }
  }, [focusInput]);

  // focus on the input
  useEffect(() => {
    if (error || (required && !val)) {
      const eInput = inputRef.current;
      eInput?.focus();
    }
  }, [error, required, val]);

  return (
    <Form validated={!error} className="w-100">
      <Form.Control
        ref={inputRef}
        className="w-100"
        type={type}
        isInvalid={(required && !val && val !== 0) || error}
        maxLength={maxLength}
        value={val}
        onChange={(e) => {
          setVal(e.target.value);
          setError("");
        }}
        required={required}
      />
    </Form>
  );
});

export default React.memo(CustomCellEditor);
