import React from "react";
import { Dropdown } from "react-bootstrap";

export type DownloadFormat = "FILEGEODATABASE" | "SHAPEFILE" | "KML" | "CSV";

export interface ILayerDownloadFormat {
  text: string;
  value: DownloadFormat;
}

const DEFAULT_TITLE = "Download Formats";

export const DEFAULT_DOWNLOAD_FORMATS: ILayerDownloadFormat[] = [
  { text: "CSV", value: "CSV" },
  { text: "File Geodatabase", value: "FILEGEODATABASE" },
  { text: "KML", value: "KML" },
  { text: "Shapefile", value: "SHAPEFILE" },
];

interface ILayerDownloadDropdownProps {
  children?: JSX.Element | JSX.Element[];
  className?: string;
  disabled?: boolean;
  formats?: ILayerDownloadFormat[];
  title?: string;
  toggleProps?: Partial<React.ComponentProps<typeof Dropdown.Toggle>>;
  variant?: string;
  onSelect?: (format: DownloadFormat) => void | Promise<void>;
}

export function LayerDownloadDropdown({
  children,
  className,
  disabled = false,
  formats = DEFAULT_DOWNLOAD_FORMATS,
  title = DEFAULT_TITLE,
  toggleProps = {},
  variant = "primary",
  onSelect,
}: ILayerDownloadDropdownProps) {
  return (
    <Dropdown className={className}>
      <Dropdown.Toggle {...toggleProps} title={title} variant={variant}>
        {children ?? title}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {formats.map((format) => (
          <Dropdown.Item
            key={format.value}
            disabled={disabled}
            onClick={() => onSelect && onSelect(format.value)}
          >
            {format.text}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
}
