import React from "react";
import { AgGridReact, AgGridReactProps } from "ag-grid-react";
import CheckboxRenderer from "./CheckboxRenderer";
import ActionCellRenderer from "./ActionCellRenderer";
import LinksAndMetadataRenderer from "./LinksAndMetadataRenderer";
import "./datatable.scss";
import CustomCellEditor from "./CustomCellEditor";

const DataTable = React.forwardRef<AgGridReact, AgGridReactProps>(
  function DataTable(props, ref) {
    return (
      <AgGridReact
        ref={ref}
        className="ag-theme-alpine"
        tooltipShowDelay={500}
        components={{
          checkboxRenderer: CheckboxRenderer,
          actionCellRenderer: ActionCellRenderer,
          customCellEditor: CustomCellEditor,
          linksAndMetadataRenderer: LinksAndMetadataRenderer,
        }}
        {...props}
      />
    );
  }
);

export default DataTable;
