import React, { useEffect, useState } from "react";
import { LayerFilterQueryOperator, LayerFilterSupportedLayers } from "../types";
import { isDate, isDomain, isNumber } from "../utils";
import LayerFilterQueryDateInput from "./LayerFilterQueryDateInput";
import LayerFilterQueryDomainInput from "./LayerFilterQueryDomainInput";
import LayerFilterQueryNumberInput from "./LayerFilterQueryNumberInput";
import LayerFilterQueryTextInput from "./LayerFilterQueryTextInput";

interface ILayerFilterQueryValueParams {
  layer?: LayerFilterSupportedLayers;
  field?: __esri.Field;
  operator?: LayerFilterQueryOperator;
  value?: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (value?: string | number) => any;
}

export default function LayerFilterQueryValueInput({
  layer,
  field,
  operator,
  value,
  onChange,
}: ILayerFilterQueryValueParams) {
  const [defaultValue, setDefaultValue] = useState<
    string | number | undefined
  >();

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {
    if (typeof value !== "undefined") setDefaultValue(value);
  }, [value]);

  useEffect(() => {
    const disabled =
      operator === LayerFilterQueryOperator.isBlank ||
      operator === LayerFilterQueryOperator.isNotBlank;

    setDisabled(disabled);
  }, [operator]);

  return isDomain(field) ? (
    <LayerFilterQueryDomainInput
      disabled={disabled}
      field={field}
      value={defaultValue}
      onChange={onChange}
    />
  ) : isDate(field) ? (
    <LayerFilterQueryDateInput
      disabled={disabled}
      value={defaultValue}
      operator={operator}
      onChange={onChange}
    />
  ) : isNumber(field) ? (
    <LayerFilterQueryNumberInput
      disabled={disabled}
      layer={layer}
      field={field}
      value={defaultValue}
      operator={operator}
      onChange={onChange}
    />
  ) : (
    <LayerFilterQueryTextInput
      disabled={disabled}
      layer={layer}
      field={field}
      value={defaultValue}
      operator={operator}
      onChange={onChange}
    />
  );
}
