import { useCallback, useEffect, useRef, useState } from "react";
import { useAppState } from "AppProvider";
import { IGroupWithItems } from "./types/Portal";
import esriRequest from "@arcgis/core/request";

export function useGroupItems() {
  const didCancel = useRef(false);
  const { portal } = useAppState();
  const [groupItems, setGroupItems] = useState<IGroupWithItems[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();

  // set flag to prevent setting state if component has unmounted
  useEffect(() => {
    didCancel.current = false;
    return function cleanup() {
      didCancel.current = true;
    };
  }, []);

  const fetchGroupItems = useCallback(
    async ({
      groupQuery,
      groupItemsQuery,
    }: {
      groupQuery: string;
      groupItemsQuery: string;
    }) => {
      if (!portal || didCancel.current) {
        return;
      }
      setIsLoading(true);
      const groupResponse = await portal.queryGroups({
        query: groupQuery,
        sortField: "modified",
        sortOrder: "desc",
        num: 100,
      });

      const itemGroupResponse = await Promise.allSettled(
        groupResponse.results.map((r: __esri.PortalGroup) =>
          r.queryItems({
            query: groupItemsQuery,
            sortField: "title",
            sortOrder: "desc",
            num: 100,
          })
        )
      );

      let hasErrors = false;
      const items: IGroupWithItems[] = [];
      for (let itemIdx = 0; itemIdx < groupResponse.results.length; itemIdx++) {
        const group = groupResponse.results[itemIdx] as __esri.PortalGroup;
        const response = itemGroupResponse[itemIdx];
        if (response.status === "rejected") {
          hasErrors = true;
          continue;
        }
        const reqs: Promise<__esri.RequestResponse>[] = [];
        response.value.results.map(async (r) => {
          if (!r.url || !r.isLayer) {
            return;
          }
          reqs.push(
            esriRequest(`${r.url}?f=pjson`, {
              responseType: "json",
            })
          );
        });
        const rest = await Promise.allSettled(reqs);
        const newItems = response.value.results.map((r) => {
          const lyrs = rest.find((l) =>
            l.status === "fulfilled" ? l.value.url === `${r.url}?f=pjson` : null
          );
          return {
            ...r.toJSON(),
            thumbnailUrl: r.thumbnailUrl,
            getThumbnailUrl: r.getThumbnailUrl,
            metadata: lyrs?.status === "fulfilled" ? lyrs.value.data : null,
          };
        });

        items.push({ group, items: newItems });
      }

      if (!didCancel.current) {
        setGroupItems(items);
        setIsLoading(false);
      }
      if (hasErrors) {
        // eslint-disable-next-line no-console
        console.error("Failed to load some or all portal items");
        if (!didCancel.current) {
          setError("Failed to load some or all portal items");
        }
      }
    },
    [portal]
  );

  return { fetchGroupItems, groupItems, isLoading, error };
}

export default useGroupItems;
